import React from 'react'

import Root from '../components/Root'
import Introduction from '../components/Introduction'
import Application from '../components/Application'
import VideoChat from '../components/VideoChat'

const Home = () => (
    <Root>
        <Introduction />
        <Application />
        <VideoChat />
    </Root>
)

export default Home

import React, { useRef, useState } from 'react'

import upload from '../../images/global/upload.svg'

import { functions } from '../../config'

import Checkbox from '../Checkbox'
import Spinner from '../Spinner'
import SVG from '../SVG'

import mrMonopolyMobile from './mr-monopoly-mobile.png'
import placeholder from './placeholder.jpg'

const { encodeFormData } = functions

const statuses = {
    PENDING: 'pending',
    SUCCESS: 'success',
    ERROR: 'error',
    LOADING: 'loading',
}

const postcodeValidator = (str = '') =>
    /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/.test(str?.toUpperCase())

const phoneValidator = (str = '') =>
    str?.replace(/ /g, '') == parseInt(str?.replace(/ /g, '')) && str.length > 0

const textValidator = (str) => str?.replace(/ /g, '')?.length > 0

const emailValidator = (str) =>
    str !== null &&
    str.replace(/\s/g, '') !== '' &&
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        str
    )

const Application = () => {
    const hiddenFile = useRef(null)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [postcode, setPostcode] = useState('')
    const [files, setFiles] = useState([])
    const [agree, setAgree] = useState(false)
    const [status, setStatus] = useState(statuses.PENDING)
    const [message, setMessage] = useState(
        `Something went wrong. Please check your details and try again.`
    )

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (
            files.length === 0 ||
            !textValidator(name) ||
            !emailValidator(email) ||
            !phoneValidator(phone) ||
            !postcodeValidator(postcode)
        ) {
            setStatus(statuses.ERROR)
        } else {
            setStatus(statuses.LOADING)
        }

        let response = await fetch('/api/submit', {
                method: 'POST',
                body: encodeFormData({
                    'form-name': 'application',
                    name,
                    email,
                    phone,
                    postcode,
                    files,
                }),
            }),
            data = await response.json()

        if (data?.success) {
            setStatus(statuses.SUCCESS)
        } else {
            if (
                data?.reason &&
                data?.reason?.includes('already a list member')
            ) {
                setMessage('You have already applied.')
            }

            setStatus(statuses.ERROR)
        }
    }

    return (
        <>
            <div
                id="apply"
                className="relative bg-red-light bg-gradient-to-t from-red-dark to-red-light text-center md:text-left"
            >
                <div className="container mx-auto px-8 md:px-12 lg:px-20 py-12 md:py-16 relative text-white">
                    <div className="flex flex-col xl:flex-row items-center">
                        <div className="flex-1 flex flex-col sm:flex-row items-center justify-between">
                            <img
                                src={mrMonopolyMobile}
                                alt="Monopoly Mobile"
                                className="w-32 md:w-48 h-auto pointer-events-none select-none"
                            />
                            <h2 className="max-w-xs md:max-w-sm mt-4 md:mt-0 md:ml-12 lg:ml-8 mx-auto font-display text-3xl md:text-4xl lg:text-5xl font-bold uppercase">
                                Apply to play with Gordon Ramsay
                            </h2>
                        </div>
                        <div className="flex-1 mt-8 md:mt-12 xl:mt-0 text-center xl:text-left">
                            <div className="xl:pl-12 2xl:pl-0">
                                <p className="max-w-6xl font-bold text-xl md:text-2xl">
                                    Applications are open until{' '}
                                    <span className="inline-block">
                                        Monday 9 August, 12pm.
                                    </span>
                                </p>
                                <p className="max-w-6xl mt-8 font-light text-xl md:text-2xl">
                                    All you have to do is complete the form and
                                    submit a 30 second video introducing
                                    yourself, and tell us why you should win!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="relative bg-bare bg-gradient-to-b from-gallery to-bare bg-cover bg-top bg-no-repeat"
                style={{ backgroundImage: `url(${placeholder})` }}
            >
                <div className="max-w-5xl mx-auto px-8 md:px-12 lg:px-20 pb-8 md:pb-16 relative">
                    <div className="bg-white p-8 md:p-16 text-center rounded-b-2xl border-l-4 border-r-4 border-b-4 border-black">
                        <h2 className="text-2xl md:text-3xl lg:text-4xl font-display font-bold text-center text-red-light uppercase">
                            {status === statuses.SUCCESS
                                ? 'Thank you!'
                                : 'Application questions'}
                        </h2>
                        {status === statuses.SUCCESS ? (
                            <>
                                <p className="mt-8 md:mt-12 text-lg">
                                    Your submission will be reviewed shortly.
                                </p>
                                <span className="flex items-center mt-8 md:mt-12">
                                    <span className="w-1/2 h-1 flex-1 bg-gradient-to-r from-white to-gallery" />
                                    <span className="w-1/2 h-1 flex-1 bg-gradient-to-l from-white to-gallery" />
                                </span>
                            </>
                        ) : (
                            <>
                                <ol className="mt-8 md:mt-12 text-lg md:text-xl font-medium text-center">
                                    <li>
                                        <span className="text-red-light">
                                            1.
                                        </span>{' '}
                                        Why do you like playing MONOPOLY?
                                    </li>
                                    <li className="mt-4">
                                        <span className="text-red-light">
                                            2.
                                        </span>{' '}
                                        Which property set do you always aim to
                                        complete first?
                                    </li>
                                    <li className="mt-4">
                                        <span className="text-red-light">
                                            3.
                                        </span>{' '}
                                        What’s your fondest MONOPOLY game
                                        memory?
                                    </li>
                                </ol>
                                <p className="mt-8">
                                    The winners will be announced on{' '}
                                    <strong className="font-bold">
                                        Friday 13 August
                                    </strong>
                                    , and the MONOPOLY game with Gordon Ramsay
                                    will take place on{' '}
                                    <strong className="font-bold">
                                        Thursday 19 August
                                    </strong>
                                    . Full terms and conditions.
                                </p>
                                <p className="mt-8">
                                    If you think you’ve got what it takes, enter
                                    now. Good luck!
                                </p>
                                <span className="flex items-center mt-8 md:mt-12">
                                    <span className="w-1/2 h-1 flex-1 bg-gradient-to-r from-white to-gallery" />
                                    <span className="w-1/2 h-1 flex-1 bg-gradient-to-l from-white to-gallery" />
                                </span>
                                <form
                                    onSubmit={handleSubmit}
                                    className="mt-8 md:mt-12 relative"
                                >
                                    {status === statuses.LOADING ? (
                                        <div className="flex items-center justify-center w-full h-full absolute top-0 left-0 bg-white z-40">
                                            <Spinner />
                                        </div>
                                    ) : null}
                                    <div className="flex flex-col">
                                        <input
                                            name="name"
                                            type="text"
                                            value={name}
                                            onChange={({
                                                currentTarget: { value },
                                            }) => setName(value)}
                                            required
                                            placeholder="Enter your name*"
                                            className={`inline-flex items-center justify-center py-4 px-8 font-light text-lg sm:text-xl md:text-2xl bg-white border ${
                                                !textValidator(name) &&
                                                name.length
                                                    ? 'border-red-light text-red-light'
                                                    : 'border-black text-black'
                                            } rounded-full leading-none text-center outline-none`}
                                        />
                                        <input
                                            name="email"
                                            type="email"
                                            value={email}
                                            onChange={({
                                                currentTarget: { value },
                                            }) => setEmail(value)}
                                            required
                                            placeholder="Enter your email address*"
                                            className={`inline-flex items-center justify-center py-4 px-8 mt-4 md:mt-8 font-light text-lg sm:text-xl md:text-2xl bg-white border ${
                                                !emailValidator(email) &&
                                                email.length
                                                    ? 'border-red-light text-red-light'
                                                    : 'border-black text-black'
                                            } rounded-full leading-none text-center outline-none`}
                                        />
                                        <input
                                            name="phone"
                                            type="text"
                                            value={phone}
                                            onChange={({
                                                currentTarget: { value },
                                            }) => setPhone(value)}
                                            required
                                            placeholder="Enter your phone number*"
                                            className={`inline-flex items-center justify-center py-4 px-8 mt-4 md:mt-8 font-light text-lg sm:text-xl md:text-2xl bg-white border ${
                                                !phoneValidator(phone) &&
                                                phone.length
                                                    ? 'border-red-light text-red-light'
                                                    : 'border-black text-black'
                                            } rounded-full leading-none text-center outline-none`}
                                        />
                                        <input
                                            name="postcode"
                                            type="text"
                                            value={postcode}
                                            onChange={({
                                                currentTarget: { value },
                                            }) => setPostcode(value)}
                                            required
                                            placeholder="Enter your postcode*"
                                            className={`inline-flex items-center justify-center py-4 px-8 mt-4 md:mt-8 font-light text-lg sm:text-xl md:text-2xl bg-white border ${
                                                !postcodeValidator(postcode) &&
                                                postcode.length
                                                    ? 'border-red-light text-red-light'
                                                    : 'border-black text-black'
                                            } rounded-full leading-none text-center outline-none`}
                                        />
                                    </div>
                                    <div
                                        className={`flex flex-col lg:flex-row items-center mt-4 md:mt-8 ${
                                            files.length > 0 ? 'mb-12' : 'mb-0'
                                        }`}
                                    >
                                        <div className="w-full md:w-auto relative">
                                            <div className="w-full md:w-auto relative">
                                                <input
                                                    ref={hiddenFile}
                                                    name="video"
                                                    type="file"
                                                    accept="video/mp4,video/x-m4v,video/*"
                                                    onChange={({
                                                        target: { files },
                                                    }) => setFiles(files)}
                                                    required
                                                    className="block w-full h-full absolute top-0 left-0 opacity-0 select-none pointer-events-none appearance-none z-10"
                                                />
                                                <button
                                                    type="button"
                                                    className={`block md:flex items-center justify-center w-full md:w-auto py-4 md:px-12 font-display font-bold text-xl md:text-2xl text-white uppercase bg-gradient-to-b from-boulder to-black hover:to-boulder rounded-full leading-none select-none z-20`}
                                                    onClick={() =>
                                                        hiddenFile?.current?.click()
                                                    }
                                                >
                                                    <span className="block transform">
                                                        Video upload
                                                        <SVG
                                                            src={upload}
                                                            className="hidden sm:inline-block w-6 h-auto ml-4 transform -translate-y-1"
                                                        />
                                                    </span>
                                                </button>
                                            </div>
                                            {files.length > 0 ? (
                                                <p className="w-full py-2 absolute top-full left-0 text-xs text-boulder">
                                                    {files?.[0]?.name}
                                                </p>
                                            ) : null}
                                        </div>
                                        <Checkbox
                                            name="agree"
                                            checked={agree}
                                            required
                                            label={
                                                <span className="font-light text-left">
                                                    Agree to{' '}
                                                    <a
                                                        href="/terms"
                                                        className="underline"
                                                        target="_blank"
                                                        rel="noopener nofollow noreferrer"
                                                        onClick={() => true}
                                                    >
                                                        Terms and conditions
                                                    </a>
                                                </span>
                                            }
                                            changeHandler={(val) =>
                                                setAgree(val)
                                            }
                                            className="flex-1 mt-4 md:mt-8 lg:mt-0 lg:ml-8"
                                        />
                                    </div>
                                    {status === statuses.ERROR ? (
                                        <p className="mt-8 py-2 px-4 border-4 border-black font-xl font-bold text-black uppercase">
                                            {message}
                                        </p>
                                    ) : null}
                                    <button
                                        type="submit"
                                        className="flex items-center justify-center w-full py-4 px-32 mt-8 font-display font-bold text-3xl md:text-4xl text-white uppercase bg-gradient-to-b from-red-light to-red-dark hover:from-black hover:to-boulder rounded-full leading-none select-none"
                                    >
                                        <span className="block transform -translate-y-1">
                                            Submit
                                        </span>
                                    </button>
                                </form>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Application

import React from 'react'

import mobile from './mobile.png'

const VideoChat = () => (
    <div className="relative bg-gradient-to-b from-bare to-gallery text-center lg:text-left">
        <div className="container mx-auto px-8 md:px-12 lg:px-20 py-12 md:py-20 lg:py-20 relative">
            <div className="flex flex-col lg:flex-row items-center">
                <div className="w-full lg:w-1/2">
                    <h2 className="max-w-md mb-8 md:mb-12 mx-auto lg:mx-0 font-display text-3xl md:text-4xl lg:text-5xl text-red-light font-bold uppercase">
                        Multiplayer video chat
                    </h2>
                    <div className="md:pr-8 lg:pr-12 font-light text-lg md:text-xl">
                        <p>
                            For the first time ever MONOPOLY on mobile includes
                            in-game multiplayer video chat. Play face-to-face
                            with friends and family wherever they are, all over
                            the world or in the same room!
                        </p>
                        <p className="mt-8">
                            There’s nothing like seeing the look on your
                            opponent’s face when they land on Park Lane plus a
                            hotel. Will you crush Gordon Ramsay with sky high
                            rents, or let him win?
                        </p>
                        <p className="mt-8">
                            Get ready to make the ultimate MONOPOLY memory
                        </p>
                    </div>
                </div>
                <div className="w-full lg:w-1/2 mt-8 md:mt-0 text-center">
                    <img
                        src={mobile}
                        alt="Monopoly Mobile"
                        className="inline-block w-full h-auto max-w-xl align-top select-none pointer-events-none"
                    />
                </div>
            </div>
        </div>
    </div>
)

export default VideoChat

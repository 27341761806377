import React from 'react'

const Checkbox = ({
    label,
    checked,
    changeHandler,
    className,
    required = true,
}) => (
    <label
        className={`flex items-center relative ${className} cursor-pointer select-none`}
    >
        <div className="flex items-center justify-center w-7 h-7 mr-4 border-2 border-black">
            {checked ? <div className="w-3 h-3 bg-black" /> : null}
        </div>
        {label}
        <input
            type="checkbox"
            value={checked}
            required
            className="w-full h-full absolute top-0 left-0 opacity-0 pointer-events-none select-none appearance-none"
            onChange={() => changeHandler(!checked)}
        />
    </label>
)

export default Checkbox

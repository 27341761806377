import React from 'react'

import './style.scss'

const Spinner = () => (
    <div className="spinner w-28 h-28 mx-auto relative text-center">
        <div className="spinner__dot spinner__dot--1 inline-block absolute top-0 rounded-full bg-red-light" />
        <div className="spinner__dot spinner__dot--2 inline-block absolute top-auto bottom-0 rounded-full bg-red-light bg-blue-light" />
        <div className="spinner__dot spinner__dot--3 inline-block absolute bottom-full top-auto rounded-full bg-gallery" />
    </div>
)

export default Spinner

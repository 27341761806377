export default {
    postcodeValidator: (str = '') =>
        /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/.test(str?.toUpperCase()),
    textValidator: (str) => str?.length > 0,
    emailValidator: (str) =>
        str !== null &&
        str.replace(/\s/g, '') !== '' &&
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            str
        ),
    priceValidator: (str) => !isNaN(str) && str > 0,
    dateValidator: (date) => date !== null,
    currencyValidator: (val) => isNaN(val) || parseInt(val) > 0,
    encodeFormData: (data) => {
        const formData = new FormData()
        Object.keys(data).map((key) => {
            if (key === 'files') {
                for (const file of data[key]) {
                    formData.append(key, file, file.name)
                }
            } else {
                formData.append(key, data[key])
            }
        })

        return formData
    },
}

import React from 'react'

import gordon from './gordon.png'
import cloud1 from '../../images/global/cloud1.png'
import cloud2 from '../../images/global/cloud2.png'
import mrMonopoly from './mr-monopoly.png'
import ferrisWheel from './ferris-wheel.png'
import buildings from './buildings.png'

const Introduction = () => {
    return (
        <div
            id="intro"
            className="relative text-center bg-gradient-to-t from-blue-dark to-blue-light overflow-hidden lg:overflow-visible"
        >
            <div className="container mx-auto px-8 md:px-12 lg:px-20 pt-12 pb-20 lg:pb-56 xl:pb-72 relative text-white">
                <h2 className="max-w-5xl mx-auto text-3xl md:text-4xl lg:text-5xl font-display font-bold uppercase">
                    Do you have what it takes to bankrupt Gordan Ramsay?
                </h2>
                <p className="max-w-5xl mx-auto mt-8 font-light text-2xl md:text-3xl">
                    Three lucky players will put their MONOPOLY skills to the
                    test, in a heated and high stakes game against the world
                    famous chef and entrepreneur.
                </p>
                <div className="max-w-3xl mx-auto mt-8">
                    <p className="font-light text-lg">
                        You’ll be one of the first to play with the brand new
                        Community Chest cards. In 85 years since their
                        introduction, the Community Chest Cards have never been
                        completely replaced, so prepare to make history. What’s
                        more, MONOPOLY on mobile has a new in-game video chat
                        feature you’ll use to connect with Gordon.
                    </p>
                    <div className="relative lg:static">
                        <img
                            src={gordon}
                            alt="Gordon Ramsay"
                            className="w-56 md:w-80 xl:w-96 2xl:w-128 max-w-full mt-8 -ml-12 sm:ml-0 lg:absolute lg:bottom-0 lg:left-0 z-20 pointer-events-none select-none"
                        />
                        <img
                            src={cloud1}
                            className="lg:hidden absolute w-96 -bottom-12 -left-32 z-20 pointer-events-none select-none"
                        />
                        <img
                            src={cloud2}
                            className="lg:hidden absolute w-48 -bottom-6 left-36 z-10 pointer-events-none select-none"
                        />
                        <img
                            src={mrMonopoly}
                            alt="Mr Monopoly"
                            className="w-72 md:w-96 xl:w-112 2xl:w-128 max-w-full absolute -bottom-12 lg:-bottom-24 xl:-bottom-32 right-12 z-20 pointer-events-none select-none"
                        />
                        <img
                            src={ferrisWheel}
                            alt="FerrisWheel"
                            className="lg:hidden w-56 md:w-80 xl:w-96 max-w-full mt-8 absolute bottom-0 -right-12 sm:-right-24 md:-right-48 z-0 pointer-events-none select-none"
                        />
                    </div>
                    <p className="mt-12 lg:mt-8 font-light text-lg">
                        For your chance to be a big shot property tycoon, apply
                        below.
                    </p>

                    <p className="mt-8 font-bold text-lg uppercase">
                        Applications open until monday 9 august 12pm.
                    </p>
                </div>
                <a
                    href="#apply"
                    className="inline-flex items-center justify-center py-4 px-12 md:px-32 mt-8 md:mt-12 relative font-display font-bold text-3xl md:text-4xl text-white uppercase bg-gradient-to-b from-red-light to-red-dark hover:from-black hover:to-boulder rounded-full leading-none cursor-pointer select-none z-10"
                >
                    <span className="block transform -translate-y-1">
                        Enter now
                    </span>
                </a>
            </div>
            <img
                src={ferrisWheel}
                alt="FerrisWheel"
                className="hidden lg:block w-56 md:w-80 xl:w-96 max-w-full mt-8 lg:absolute lg:bottom-0 lg:right-0 z-0 pointer-events-none select-none"
            />
            <img
                src={buildings}
                className="hidden lg:block w-3/5 max-w-full absolute z-0 -bottom-8 left-1/2 transform -translate-x-1/2 opacity-50 z-0"
                alt="MONOPOLY"
            />
        </div>
    )
}

export default Introduction
